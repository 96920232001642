export default {
  name: 'App',
  methods: {
    updateHtmlLang(lang) {
      document.documentElement.lang = lang
    }
  },
  watch: {
    '$route.params.lang': {
      immediate: true,
      handler(newLang) {
        this.updateHtmlLang(newLang)
      }
    }
  }
}
