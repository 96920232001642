import axios from 'axios'

const loadLanguage = async ({ commit }, lang) => {
  await commit('updateLanguage', lang)
  await commit('updateLocale')
}

const loadPageInfo = async (settingId, getLang, {
  commit,
  getters
}) => {
  const lang = getLang()
  const { data: pageInfo } = await axios.get(
    `${getters.apiUrl}/api/setting/${settingId}?lang=${lang}`,
    getters.apiHeader
  )
  await commit('updatePageInfo', pageInfo)
}

const loadStartPage = (context) =>
  loadPageInfo(1, () => localStorage.getItem('locale'), context)
const loadMyKitchenBottomText = (context) =>
  loadPageInfo(2, () => localStorage.getItem('locale'), context)
const loadStartPageDifLang = (context) =>
  loadPageInfo(
    1,
    () => document.querySelector('.c-language-switcher__button')?.dataset?.lang,
    context
  )
const loadMyKitchenBottomTextDifLang = (context) =>
  loadPageInfo(
    2,
    () => document.querySelector('.c-language-switcher__button')?.dataset?.lang,
    context
  )

const loadCategory = async ({
                              commit,
                              getters
                            }, categorySlug) => {
  /* if (getters.categorieslist && getters.categorieslist.length) {
    const catData = data => {
      const subCatObject = getters.categorieslist.forEach(parentCat => {
        parentCat.child_categories.find(subCat => subCat.slug === categorySlug)
      })
      const res = getters.categorieslist.find(parentCat => parentCat.slug === categorySlug)
      return res || subCatObject
    }
    await commit('updateCategory', catData.data)
    await commit('updateCategoryId', catData.data.id)
    await commit('updateCategorySlug', categorySlug)
  } else { */
  // const curLang = document.querySelector('.c-language-switcher__button')
  //   ?.dataset?.lang
  const curLang = localStorage.getItem('locale')
  const { data: categoryinfo } = await axios.get(
    `${getters.apiUrl}/api/category/slug/${categorySlug}?lang=${curLang}`,
    getters.apiHeader
  )
  await commit('updateCategory', categoryinfo.data)
  await commit('updateCategorySlug', categorySlug)
  await commit('updateCategoryId', categoryinfo.data.id)
  /* } */
}
const loadCategoryProducts = async ({
                                      commit,
                                      getters
                                    }, categoryId) => {
  const { data: categoryProducts } = await axios.get(
    `${
      getters.apiUrl
    }/api/products?cat_id=${categoryId}&lang=${localStorage.getItem('locale')}`,
    getters.apiHeader
  )

  // console.debug(categoryId)

  await commit('updateCategoryProducts', categoryProducts.data)
}
const loadKitchen = async ({
                             commit,
                             getters
                           }, kitchenSlug) => {
  const { data: kitchenInfo } = await axios.get(
    `${
      getters.apiUrl
    }/api/kitchen/slug/${kitchenSlug}?lang=${localStorage.getItem('locale')}`,
    getters.apiHeader
  )
  kitchenInfo.data.category_products.sort((a, b) =>
    a.id > b.id ? 1 : b.id > a.id ? -1 : 0
  )
  await commit('updateKitchen', kitchenInfo)
}

const loadCategoriesList = async ({
                                    commit,
                                    getters
                                  }) => {
  const curLang = document.querySelector('.c-language-switcher__button')
    ?.dataset?.lang
  if (getters.categorieslist.length === 0) {
    const { data: categorieslist } = await axios.get(
      `${getters.apiUrl}/api/category/list?lang=${curLang}`,
      getters.apiHeader
    )
    categorieslist.data.sort((a, b) =>
      a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0
    )
    await commit('updateCategoriesList', categorieslist.data)
  }
}

const changeCategoriesListLang = async ({
                                          commit,
                                          getters
                                        }, lang) => {
  const { data: categorieslist } = await axios.get(
    `${getters.apiUrl}/api/category/list?lang=${lang}`,
    getters.apiHeader
  )
  categorieslist.data.sort((a, b) =>
    a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0
  )
  await commit('updateCategoriesList', categorieslist.data)
}

const loadStep = async ({
                          commit,
                          getters
                        }, stepId) => {
  const curLang = document.querySelector('.c-language-switcher__button')
    ?.dataset?.lang
  const { data: stepInfo } = await axios.get(
    `${getters.apiUrl}/api/step/${stepId}?lang=${curLang}`,
    getters.apiHeader
  )
  /* stepInfo.sort((a, b) => (a.ordinal > b.ordinal) ? 1 : ((b.ordinal > a.ordinal) ? -1 : 0)) */
  await commit('updateStep', stepInfo.data)
  await commit('updateStepId', stepInfo.data.id)
}

const addChosenFeatures = async ({ commit }, features) => {
  await commit('addChosenFeaturesToChoices', features)
}

const updateReturnUrl = async ({ commit }, redirectUrl) => {
  await commit('updateReturnUrl', redirectUrl)
}

const toggleCookieConsent = ({ commit }, open) => {
  commit('toggleCookieConsent', open)
}

const setCookieConsent = ({ commit }, consent) => {
  commit('setCookieConsent', consent)
}

const removeChosenFeatures = async ({ commit }, features) => {
  await commit('removeChosenFeaturesFromChoices', features)
}

export default {
  loadLanguage,
  loadStartPage,
  loadMyKitchenBottomText,
  loadCategory,
  loadKitchen,
  loadCategoriesList,
  changeCategoriesListLang,
  loadStartPageDifLang,
  loadMyKitchenBottomTextDifLang,
  loadCategoryProducts,
  loadStep,
  addChosenFeatures,
  removeChosenFeatures,
  updateReturnUrl,
  setCookieConsent,
  toggleCookieConsent,
}
