import Button from '@/components/Button/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import BasicFeature from '@/components/BasicFeature/BasicFeature.vue'
import ChoosableFeature from '@/components/ChoosableFeature/ChoosableFeature.vue'
import Product from '@/components/Product/Product.vue'
import ChildProduct from '@/components/Product/ChildProduct/ChildProduct.vue'
import TextMedia from '@/components/TextMedia/TextMedia.vue'
import Link from '@/components/Link/Link.vue'
import Assortment from '@/components/Assortment/Assortment.vue'
import StepHead from '@/components/StepHead/StepHead.vue'
import StepButtons from '@/components/StepButtons/StepButtons.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import axios from 'axios'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  Name: 'Step',
  components: {
    'v-button': Button,
    'v-icon': Icon,
    'v-basic-feature': BasicFeature,
    'v-choosable-feature': ChoosableFeature,
    'v-product': Product,
    'v-link': Link,
    'v-textmedia': TextMedia,
    'v-child-product': ChildProduct,
    'v-assortment': Assortment,
    'v-stephead': StepHead,
    'v-stepbuttons': StepButtons
  },
  data () {
    return {
      route: this.$route.path,
      locale: this.$route.params.lang,
      slug: this.$route.params.slug,
      sizes: [],
      children: [],
      products: [],
      alternativeProducts: [],
      addedProductFeaturesContent: [],
      sortedChildren: [],
      sortedChildFeatures: [],
      loading: false,
      translations
    }
  },
  async mounted () {

    if (this.stepInfo) {

      if (this.stepInfo.show_result === 1 && !this.addedProduct) {
        if (this.products.length < 1) {
          this.products = this.categoryinfo.products

          this.addedProduct = this.products.filter(obj => {
            if (obj.id === 54) {
              return obj
            } else {
              return false
            }
          })
        }
      }
      if (this.stepInfo.show_result === 1) {
        if (this.temporaryChosenFeatures.length > 0) {
          this.choices(this.temporaryChosenFeatures)
          this.clearTemporaryChosenFeatures()
        }
      }
    }

    await this.loadContent()

    if (this?.stepInfo?.is_design_step === 1) {
      this.clearDesignChoices()
      this.clearSizeRestrictions()
    }

    // const payload = { id: this.stepId, slug: this.slug }
    // this.removeFromHistory(payload)
  },
  computed: {
    ...mapGetters([
      'stepInfo',
      'categoryinfo',
      'categorieslist',
      'apiUrl',
      'apiHeader',
      'appBaseUrl',
      'token',
      'stepHistory',
      'chosenFeatures',
      'chosenDesignFeatures',
      'addedProduct',
      'addedProducts',
      'kitchenId',
      'stepCounter',
      'kitchenInfo',
      'sizeRestrictions',
      'chosenFeaturesObject',
      'temporaryChosenFeatures'
    ])
  },
  methods: {
    ...mapActions(['loadCategoriesList', 'loadStep', 'loadCategory', 'loadCategoryProducts']),
    ...mapMutations([
      'updateHistory',
      'createHistoryObject',
      'removeFromHistory',
      'categoryDone',
      'addProduct',
      'choices',
      'designChoices',
      'clearDesignChoices',
      'updateChoices',
      'updateKitchen',
      'clearChoices',
      'updateAddedProducts',
      'createProductObject',
      'clearAddedProduct',
      'updateStepCounter',
      'clearSizeRestrictions',
      'addSizeRestrictions',
      'updateChosenFeaturesObject',
      'clearTemporaryChosenFeatures'
    ]),
    isSecondOccurrence(index) {
      return index === 1 && this.stepInfo?.categories[0]?.slug === 'hobs-and-hoods'
    },
    clearAddedProductObject: function () {
      this.clearAddedProduct()
    },
    loadContent: async function () {
      this.loading = true
      this.stepId = Number.parseInt(this.$route.params.stepId)
      await this.loadStep(this.stepId)
      // IF Categoryinfo is not in state request it
      //if (this.categoryinfo.slug !== this.slug) {
      await this.loadCategory(this.slug)

      if (this.stepId === 67) {
        await this.loadCategoryProducts([10, 11])
      } else {
        await this.loadCategoryProducts(this.categoryinfo.id)
      }

      //}
      if (this.stepInfo.show_assortment || this.stepInfo.show_result) {
        this.getProducts()
      }
      this.loading = false
      this.sortedChildren = this.stepInfo.child_steps.sort((a, b) => (a.ordinal > b.ordinal) ? 1 : ((b.ordinal > a.ordinal) ? -1 : 0))

      const hideFirstInfo = this.$store.state.sizeRestrictions[0] === 45 && this.$store.state.stepId === 31 && !this.$store.state.chosenFeatures.length

      if (hideFirstInfo) {
        this.sortedChildren = this.sortedChildren.slice(1)
      }

      /* if (this.addedProduct && this.stepInfo.show_result === 1) {
        this.addedProduct.features.forEach((value) => {
          axios.get(`${this.apiUrl}/api/feature/${value.id}`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
                'Content-Type': 'application/json'
              }
            }
          ).then((response) => {
            this.addedProductFeaturesContent.push(response.data.data)
            this.addedProductFeaturesContent.sort((a, b) => (a.ordinal > b.ordinal) ? 1 : ((b.ordinal > a.ordinal) ? -1 : 0))
          })
        })
      } */
      if (this.chosenFeaturesObject.stepId === this.stepId) {
        this.updateChosenFeaturesObject({
          stepId: null,
          features: null
        })
        this.clearChoices()
      }
      this.removeThisStepFromHistory(this.stepId)
    },
    getProductsSizesForFeature: function (id) {
      let productSizesForFeature = []
      this.products = this.products.filter(obj => {
        const productFeatures = []
        obj.features.forEach(feature => productFeatures.push(feature.id))
        if (productFeatures.includes(id)) {
          productSizesForFeature.push(obj.size)
          return obj.size
        } else {
          return false
        }
      })
      productSizesForFeature = [...new Set(productSizesForFeature)]
      return productSizesForFeature
    },

    decreaseStepCounter: function () {
      this.updateStepCounter('decrease')
    },

    increaseStepCounter: function () {
      this.updateStepCounter('increase')
    },
    getProducts: function () {
      if (this.chosenFeatures && this.chosenFeatures.length) {
        this.products = this.categoryinfo.products // for testing

        this.filterProductsBasedOnSizes()

        if (this.stepId !== 44 && this.stepId !== 83) {
          this.filterProductsBasedOnFeatures()
        }

        if (this.stepId !== 89 && this.stepId !== 86) {
          this.filterAlternativeProducts(this.products)
        }
      } else {
        this.products = this.categoryinfo.products
        // First filter based on Size
        if (this.categoryinfo.slug !== 'espresso' && this.categoryinfo.slug !== 'wine-cooler') {
          if (this.chosenDesignFeatures.length && this.chosenDesignFeatures.length) {
            this.filterProductsBasedOnDesignFeatures()
          }
          this.filterProductsBasedOnSizes()
        }
      }
    },
    filterProductsBasedOnSizes: function () {
      const sizesArray = []
      if (this.sizeRestrictions && this.sizeRestrictions.length > 0) {
        this.sizeRestrictions.forEach(el => sizesArray.push(Number.parseInt(el)))
        this.products = this.products.filter(obj => {
          if (sizesArray.includes(obj.size)) {
            return obj
          } else {
            return false
          }
        })
      } else {
        this.products = this.products.filter(obj => {
          /* if (parseInt(obj.hide_in_assortment) === 1) {
            // ('Hide in assortment = True 2')
            return false
          } else {
            return obj
          } */
          return obj
        })
      }
    },
    filterFeaturesBasedOnSize: (sizeRestrictions, availableSizes) => {
      if (sizeRestrictions.length) {
        if (availableSizes.some(s => sizeRestrictions.includes(Number.parseInt(s)))) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    filterProductsBasedOnFeatures: function () {
      // Filter actual results
      this.products = this.products.filter(obj => {
        const productFeatures = []
        obj.features.forEach(feature => productFeatures.push(feature.id))
        if (productFeatures.length === 0) {
          // if product features is empty do not return product.
          return false
        } else {
          // console.debug(this.chosenFeatures)
          // console.debug(productFeatures)
          if (this.chosenFeatures.every(f => productFeatures.includes(f))) {
            if (this.categoryinfo.slug === 'hoods' && this.stepInfo.show_assortment === 1 && (this.addedProducts.hobs?.length && this.addedProducts.hobs[0].size)) {
              if (obj.size >= this.addedProducts.hobs[0].size) {
                return obj
              } else {
                return false
              }
            } else if (this.categoryinfo.slug === 'ovens') {
              if (this.chosenFeatures.length === 1 && productFeatures.length > 1) {
                if (this.chosenFeatures.includes(58)) {
                  return false
                } else {
                  if (productFeatures.includes(58)) {
                    return false
                  } else {
                    return obj
                  }
                }
              } else {
                return obj
              }
            } else {
              return obj
            }
          } else {
            return false
          }
        }
      })
    },
    filterProductsBasedOnDesignFeatures: function () {
      // Filter actual results
      this.products = this.products.filter(obj => {
        const productDesignFeatures = []
        obj.design_features.forEach(feature => productDesignFeatures.push(feature.id))
        if (productDesignFeatures.length === 0) {
          return false
        } else {
          if (this.chosenDesignFeatures.some(f => productDesignFeatures.includes(f))) {
            return obj
          } else {
            return false
          }
        }
      })
    },
    filterAlternativeProducts: function (products) {
      // Filter Alternative Products
      const productIds = []
      if (this.categoryinfo.slug !== 'ovens') {
        this.alternativeProducts = this.categoryinfo.products.filter(obj => {
          products.forEach((product) => {
            if (!productIds.includes(product.id)) {
              productIds.push(product.id)
            }
          })
          if (productIds.includes(obj.id)) {
            return false
          } else {
            return true
          }
        })
        this.alternativeProducts = this.alternativeProducts.filter(obj => {
          const productFeatures = []
          obj.features.forEach(feature => productFeatures.push(feature.id))
          if (this.chosenFeatures.length === 0) {
            return false
          } else {
            if (this.chosenFeatures.every(f => productFeatures.includes(f))) {
              return obj
            } else {
              return false
            }
          }
        })
      } else {
        this.alternativeProducts = []
      }
    },
    /*     saveToKitchen: async function (productId, productSize) {
      this.createProductObject()
      const parentCategorySlug = this.categoryinfo.find(category => category.id === this.categoryinfo.data.parent_id)
      const payload = { id: productId, size: productSize, slug: this.slug, parentSlug: parentCategorySlug ? parentCategorySlug.slug : null }
      this.updateAddedProducts(payload)
      const productIds = Object.values(this.addedProducts).flat(1)
      await axios.post(`${this.apiUrl}/api/kitchen/products`,
        {
          ...(this.kitchenId ? { id: this.kitchenId } : {}),
          product_ids: productIds
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        this.updateKitchen(response.data)
        this.clearSizeRestrictions()
      })
    }, */
    saveHistory: function (stepId, url) {
      this.createHistoryObject()
      const parentCategorySlug = this.categorieslist.find(category => category.id === this.categoryinfo.parent_id)
      const payload = {
        id: stepId,
        slug: this.slug,
        parentSlug: parentCategorySlug ? parentCategorySlug.slug : null
      }
      this.updateHistory(payload)
      this.increaseStepCounter()
      this.$router.push({ path: `/${this.locale}${url}` })
    },

    removeThisStepFromHistory: function (stepId) {
      const parentCategorySlug = this.categorieslist.find(category => category.id === this.categoryinfo.parent_id)
      const payload = {
        id: stepId,
        slug: this.slug,
        parentSlug: parentCategorySlug ? parentCategorySlug.slug : null
      }
      this.removeFromHistory(payload)
    },

    getUniqueProductSizes: (products) => {
      const uniqueSizes = []
      if (products) {
        products.forEach(product => uniqueSizes.push(product.size))
        return uniqueSizes.filter((item, pos, self) => self.indexOf(item) === pos).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      } else {
        return uniqueSizes
      }
    },
    saveSizes: function (sizes) {
      const sizesArray = []
      sizes.forEach(size => sizesArray.push(Number.parseInt(size)))
      // this.clearSizeRestrictions()
      this.addSizeRestrictions(sizesArray)
    },
    saveFeatures: function (child, designStep) {
      let featureIds = []
      let features = []
      let highlightedFeatures = []
      let designFeatures = []
      let designFeatureIds = []
      if (designStep !== 1) {
        if (child.features) {
          features = child.features.map(feature => feature.id)
        }
        if (child.highlighted_features) {
          highlightedFeatures = child.highlighted_features.map(feature => feature.id)
        }
        if (features.length && highlightedFeatures.length) {
          featureIds = features.concat(highlightedFeatures)
        } else if (features.length) {
          featureIds = features
        } else {
          featureIds = highlightedFeatures
        }
        this.choices(featureIds)
        this.updateChosenFeaturesObject({
          stepId: this.stepId,
          features: featureIds
        })
      } else {
        if (child.design_features) {
          designFeatures = child.design_features.map(designFeature => designFeature.id)
        }
        if (designFeatures.length) {
          designFeatureIds = designFeatures
        }
        this.designChoices(designFeatureIds)
      }
    }
  }
}
