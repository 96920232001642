export const SWEDISH_TRANSLATIONS = {
  text: {
    i_want: 'Jag vill',
    about_your_new: 'Om din nya',
    you_also_get: 'Du får också',
    back: 'Tillbaka',
    skip: 'Hoppa över det här steget',
    go_forward_to_hoods: 'Fortsätt med fläkt',
    itemscount: 'föremål i Mitt kök',
    removeitem: 'ta bort produkt',
    add_to_my_kitchen: 'Lägg till i My Kitchen',
    send: 'skicka',
    enteremail: 'Skriv in e-mail',
    dont_have_time_right_now: 'Har du inte tid just nu?',
    remind_me_later: 'Påminn mig senare',
    added_to_your_wishlist: 'har lagts till i din önskelista',
    added_to_my_kitchen: 'har lagts till i din My Kitchen.',
    here_are_all_the_appliances_that_you_want_in_your_next_dream_kithcen: 'Här är alla produkter du har valt till ditt drömkök',
    unique_link_for_your_kitchen: 'Unik länk till dina personliga val i my Kitchen',
    send_your_kitchen_to_your_email: 'Skicka köket till din e-post',
    pdf_successfully_sent_to_your_email: 'PDF ble sendt til e-posten din!',
    copy: 'Kopiera',
    download_package_as_pdf: 'Ladda ner paketet som PDF',
    print: 'Skriva ut',
    my_kitchen: 'My Kitchen',
    insert_your_email: 'Fyll i din mailadress',
    send_reminder: 'Skicka påminnelse',
    email_required: 'Emailadress behövs',
    valid_email_required: 'Giltig emailadress behövs',
    reminder_is_sent_to_your_email: 'Påminnelse är skickad till din emailadress!',
    close: 'Stäng',
    need_a_different_size: 'Behöver du en annan storlek?',
    download_detail_product_pdf: 'Ladda ner detaljerat produktblad i PDF',
    bundle: 'Paketförslag',
    or_do_you_need_the_little_sister_with_most_of_above_functions: 'Eller vill du ha lillasystern med de flesta funktioner ovan, fast inte lika sofistikerade?',
    features: 'funktioner',
    continue_to_next_step: 'Fortsätt till nästa steg',
    your_kitchen_is_empty: 'My Kitchen är tomt, addera några produkter',
    downloading: 'Laddar ner',
    get_back_to_where_you_started: 'Gå tillbaka till där du startade guiden',
    info_about_concept: 'Info om NEFF Collection konceptet',
    accept: 'Acceptera',
    decline: 'Avböj',
    please_accept: 'Vänligen acceptera cookie-samtycke för att spela upp den här videon',
    cookies_text: 'Om du klickar på "Acceptera" tillåter du oss att registrera ditt användningsbeteende på denna webbplats. Detta gör att vi kan förbättra vår webbplats och anpassa reklam för dig. Om du klickar på "avböj" kommer vi endast att använda sessionscookies för att förbättra användarvänligheten och mäta statistik.',
    start_over: 'Börja om',
    retailer_text: 'NEFF Collection ger dig allt man förväntar sig när man köper ett exceptionellt bra kök: mer rådgivning, mer service och mer trygghet, via studioLine certifierade köksåterförsäljare.',
    retailer_link_text: 'Påbörja din köksresa och boka möte med din köksplanerare',
  }
}
