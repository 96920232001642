<template>
  <div class="flex items-center justify-between">
    <div class="mr-4">
      <v-link
        id="show-modal"
        :text="translations[locale].text.back"
        url=""
        icon="arrow-left"
        iconSize="12"
        iconLeft
        classes="text-black"
        @click.prevent="$router.go(-1)"
      />
    </div>

    <div class="">
      <v-button
        variation="secondary"
        :text="this.$route.params?.stepId === '10' ? translations[locale].text.go_forward_to_hoods : translations[locale].text.skip"
        @click="skipStep"
      />
    </div>
  </div>
</template>

<script>
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import Button from '@/components/Button/Button.vue'
import Link from '@/components/Link/Link.vue'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}
export default {
  name: 'StepButtons',
  data () {
    return {
      locale: this.$route.params.lang,
      translations
    }
  },
  methods: {
    skipStep () {
      const activeElement = document.querySelector('.router-link-active.c-progressbar__link')
      let routeHref = activeElement.parentElement.nextElementSibling.getElementsByTagName('a')[0].getAttribute('href')

      if (this.$route.params?.stepId === '10') {
        routeHref = `/${this.$route.params?.lang}/category/hoods/14/`
      }

      this.$router.push(routeHref)
    }
  },
  components: {
    'v-button': Button,
    'v-link': Link
  }
}

</script>
