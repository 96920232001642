export const FINNISH_TRANSLATIONS = {
  text: {
    i_want: 'Haluan',
    about_your_new: 'Tietoja uudesta',
    you_also_get: 'Lisäominaisuudet',
    back: 'Takaisin',
    skip: 'Ohita tämä vaihe',
    go_forward_to_hoods: 'Jatka Liesituulettimiin',
    itemscount: 'kohteita My Kitchenissä',
    removeitem: 'Poista tuote',
    add_to_my_kitchen: 'Lisää My Kitchen',
    send: 'lähetä',
    enteremail: 'Syötä sähköposti',
    dont_have_time_right_now: 'Eikö sinulla ole aikaa juuri nyt?',
    remind_me_later: 'Muistuta minua myöhemmin',
    added_to_your_wishlist: 'lisättiin toiveluetteloosi.',
    added_to_my_kitchen: 'lisätty My Kitchen.',
    here_are_all_the_appliances_that_you_want_in_your_next_dream_kithcen: 'Haluat nämä laitteet seuraavaan unelmiesi keittiöön',
    unique_link_for_your_kitchen: 'Linkki juuri sinun keittiöösi',
    send_your_kitchen_to_your_email: 'Lähetä keittiösi sähköpostiisi',
    pdf_successfully_sent_to_your_email: 'PDF lähetetty sähköpostiisi onnistuneesti!',
    copy: 'Kopioi',
    download_package_as_pdf: 'Lataa paketti PDF-tiedostona',
    print: 'Tulosta',
    my_kitchen: 'My Kitchen',
    insert_your_email: 'Lisää sähköpostiosoitteesi',
    send_reminder: 'Lähetä muistutus',
    email_required: 'Sähköpostiosoite tarvitaan',
    valid_email_required: 'Vaaditaan voimassaoleva sähköposti',
    reminder_is_sent_to_your_email: 'Muistutus lähetetään sähköpostiisi!',
    close: 'Sulje',
    need_a_different_size: 'Tarvitsetko toisen koon?',
    download_detail_product_pdf: 'Lataa tuotetiedot pdf',
    bundle: 'Pakettiehdotukset',
    or_do_you_need_the_little_sister_with_most_of_above_functions: 'Vai tarvitsetko mallin, jossa on useimmat yllä mainitut toiminnot, mutta ei kaikkia samoja yksityiskohtia?',
    features: 'ominaisuuksia',
    continue_to_next_step: 'Jatka seuraavaan vaiheeseen',
    your_kitchen_is_empty: 'Keittiösi on tyhjä. Lisää joitain tuotteita.',
    downloading: 'Ladataan',
    get_back_to_where_you_started: 'Palaa takaisin keittiöoppaan alkuun',
    info_about_concept: 'Tietoja NEFF Collection konseptista',
    accept: 'Hyväksy',
    decline: 'Ei kiitos',
    please_accept: 'Hyväksy evästeen suostumus tämän videon toistamiseen',
    cookies_text: 'Painamalla "Hyväksy", annat meille suostumuksen käyttää evästeitä tällä sivulla. Tämän avulla voimme parantaa verkkosivustojamme ja personoida markkinointiviestintää sinulle. Painamalla "Ei kiitos", käytämme ainoastaan istuntoevästeitä käyttäjäystävällisyyden parantamiseksi ja mittaamaan tilastoja.',
    start_over: 'Aloita alusta',
    retailer_text: 'NEFF Collection antaa sinulle kaiken, mitä voit odottaa ostaessasi poikkeuksellisen keittiön: enemmän neuvoja, enemmän palvelua ja enemmän turvallisuutta sertifioitujen studioLine-keittiömyyjien ansiosta.',
    retailer_link_text: 'Aloita keittiön uudistaminen ja varaa tapaaminen keittiösuunnittelijasi kanssa',
  }
}
